import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WmService {

  apiUrl: string = environment.apiURL;
  fastApiUrl: string = environment.fastApiURL;
  hideheader: any;

  constructor(private http: HttpClient) { }

  setHideHeader(value: boolean) {
    this.hideheader = value;
    console.log(value);
  }

  getHideHeader() {
    return this.hideheader;
  }

  generateUniqueId(): string {
    return uuidv4();
  }

  getFacilityType() {
    return this.http.get(`${this.apiUrl}` + '/v1/water-mapping/facility_types')
  }
  getFluids(type: any) {
    return this.http.get(`${this.apiUrl}/v1/water-mapping/fluids?type=${type}`)
  }
  getFluidType() {
    return this.http.get(`${this.apiUrl}` + '/v1/water-mapping/fluid_types')
  }
  getParameters(type: any) {
    return this.http.get(`${this.apiUrl}` + `/v1/water-mapping/parameter_list?parameter_type=${type}`)
  }

  OnboardService(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/plant_configurations_onboard', data)
  }

  getMonthReport(start_date : any,end_date : any,service_id :any,email : any,executive_summary :any) {
    let payload:any = {
      "start_date": start_date,
      "end_date": end_date,
      "service_id": service_id,
      "email": email,
      "executive_summary":executive_summary
    }
    return this.http.post(`${this.fastApiUrl}` + `/v1/wm/monthly_report`,payload)
  }

  getPlants() {
    return this.http.get(`${this.apiUrl}` + '/v1/plant')
  }

  wmServicePlants(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/wm_service_plants', data)
  }
  getWmServicePlants() {
    return this.http.get(`${this.apiUrl}` + '/v1/water-mapping/wm_service_plants')
  }
  saveService(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/plant_configurations_save', data)
  }
  getSavedService(id: string) {
    return this.http.get(`${this.apiUrl}/v1/water-mapping/plant_configurations_save?service_id=${id}`)
  }

  addSample(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/wm_aqualog_sample', data)
  }

  updateSample(id: any, data: any) {
    return this.http.put(`${this.apiUrl}` + `/v1/water-mapping/wm_aqualog_sample/${id}`, data)
  }
  deleteSample(id: any) {
    return this.http.delete(`${this.apiUrl}` + `/v1/water-mapping/wm_aqualog_sample/${id}`)
  }

  getSampleList(id: any) {
    return this.http.get(`${this.apiUrl}/v1/water-mapping/wm_aqualog_sample?service=${id}`)
  }
  getSampleById(id: any) {
    return this.http.get(`${this.apiUrl}` + `/v1/water-mapping/wm_aqualog_sample/${id}`)
  }

  getParametersLC(id: any) {
    return this.http.get(`${this.apiUrl}/v1/water-mapping/wm_aqualog_list/${id}`)
  }
  saveLabData(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/wm_aqualog_reading', data)
  }
  getProductData(id: any) {
    return this.http.get(`${this.apiUrl}/v1/water-mapping/wm_product_types?service=${id}`)
  }
  saveProductData(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/wm_product_types', data)
  }
  insertProductData(data: any) {
    return this.http.post(`${this.apiUrl}` + '/v1/water-mapping/wm_daily_production', data)
  }

  get_cards_details(start_date: any,end_date:any,service:any) {
    return this.http.get(`${this.fastApiUrl}/v1/wm/get_cards_details?start_date=${start_date}&end_date=${end_date}&service_id=${service}`)
  }
  
  get_top_facilities_consumptions_deviation(start_date: any,end_date:any,service:any)
  {
    return this.http.get(`${this.fastApiUrl}/v1/wm/get_top_facilities_consumptions_deviation?start_date=${start_date}&end_date=${end_date}&service_id=${service}`)
  }

  card_line_chart(start_date: any,end_date:any,service:any,params:any) {
    return this.http.get(`${this.fastApiUrl}/v1/wm/card_line_chart?start_date=${start_date}&end_date=${end_date}&service_id=${service}&metric_name=${encodeURIComponent(params)}`)
  }
}
