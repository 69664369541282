import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {
    user!: User;
    currentUserValue: any;

    constructor(private httpClient: HttpClient,private router: Router) {
        console.log(environment.apiURL);
     }

    
    
    signin(creds: any) {
        // this.authenticated = true;
        return this.httpClient.post(environment.apiURL + `/v1/get_token`, creds, {withCredentials: false})
        // this.store.setItem("demo_login_status", true);
        // return of({}).pipe(delay(1500));
    }

    signout() {
        // this.authenticated = false;
        // this.store.setItem("demo_login_status", false);
        localStorage.clear();
        localStorage.removeItem('token')
        this.router.navigateByUrl("/sessions/signin");
      }

    // /**
    //  * Performs the register
    //  * @param email email
    //  * @param password password
    //  */
    // register(email: string, password: string) {
    //     return getFirebaseBackend()!.registerUser(email, password).then((response: any) => {
    //         const user = response;
    //         return user;
    //     });
    // }

    // /**
    //  * Performs the auth
    //  * @param email email of user
    //  * @param password password of user
    //  */
    // login(email: string, password: string) {
    //     return getFirebaseBackend()!.loginUser(email, password).then((response: any) => {
    //         const user = response;
    //         return user;
    //     });
    // }

    // /**
    //  * Returns the current user
    //  */
    // public currentUser(): any {
    //     return getFirebaseBackend()!.getAuthenticatedUser();
    // }

    // /**
    //  * Logout the user
    //  */
    // logout() {
    //     // logout the user
    //     return getFirebaseBackend()!.logout();
    // }

    // /**
    //  * Reset password
    //  * @param email email
    //  */
    // resetPassword(email: string) {
    //     return getFirebaseBackend()!.forgetPassword(email).then((response: any) => {
    //         const message = response.data;
    //         return message;
    //     });
    // }

}

