<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/app-list" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../../../../assets/icons/logo.png" alt="" height="25">
                    </span>
                    <span class="logo-lg">
                        <img src="../../../../assets/icons/logo.png" alt="" height="90" width="80">
                    </span>
                </a>

                <a routerLink="/app-list" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="../../../../assets/icons/logo.png" alt="" height="25"  >
                    </span>
                    <span class="logo-lg">
                        <img src="../../../../assets/icons/logo.png" alt="" height="90" width="80">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 header-item menu-section-class" id="vertical-menu-btn"
                (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>

            <!-- App Search-->
            <!-- <form class="app-search d-none d-lg-block">
                <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Search...">
                    <button class="btn btn-primary" type="button"><i class="bx bx-search-alt align-middle"></i></button>
                </div>
            </form> -->
        </div>

        <!-- <div class="d-flex">


            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item bg-soft-light border-start border-end"
                    id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    ngbDropdownToggle>
                    <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                        alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ms-1 fw-medium">{{name}}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
                            class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-logout">{{ 'MENUITEMS.AUTHENTICATION.LIST.LOGOUT' |
                            translate}}</span></a>
                </div>
            </div>

        </div> -->
    </div>
</header>