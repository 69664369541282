<div id="layout-wrapper">
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()" (settingsButtonClicked)="onSettingsButtonClicked()">
    </app-topbar>
    <app-sidebar></app-sidebar>
    <div class="main-content" id="mainContent">
        <ng-container *ngIf="!isDashboardRoute">
            <div class="page-content">
                <div class="container-fluid">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isDashboardRoute">
            <router-outlet></router-outlet>
        </ng-container>
        <app-footer></app-footer>
    </div>
</div>
