import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
// import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
        private authService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
	
		if(!request.url.includes('user')) {

			let token = localStorage.getItem('Token');
			if (token) {
				request = request.clone({
					setHeaders: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					}
				});
			}
		}
		
		return next.handle(request);
	}
}
