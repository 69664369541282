import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [    
    {
        id: 6,
        label: 'MENUITEMS.APPLICATION.TEXT',
        icon: 'cpu',
        link: '/app-list',
    },
    {
        id: 6,
        label: 'MENUITEMS.WMAPPING.TEXT',
        icon: 'droplet',
        link: '/water-mapping',
    },
    
    {
        id: 6,
        label: 'MENUITEMS.ALERTING.TEXT',
        icon: 'alert-triangle',
        link: '/alerts',
    },
    
    {
        id: 2,
        label: 'MENUITEMS.FILEDOWNLOAD.TEXT',
        icon: 'download',
        link: '/file-download',
        // subItems: [
        //     {
        //         id: 3,
        //         label: 'MENUITEMS.DATAHISTORY.TEXT',
        //         icon: 'users',
        //         link: '/file-download',
        //     }, 
        //     {
        //         id: 4,
        //         label: 'MENUITEMS.ALERTSHISTORY.TEXT',
        //         icon: 'users',
        //         link: '/file-download',
        //     },
        // ]
    },
    
    {
        id: 6,
        label: 'MENUITEMS.REMOTEACCESS.TEXT',
        icon: 'external-link',
        link: '',
    },
    {
        id: 10,
        label: 'MENUITEMS.FILEMANAGER.TEXT',
        icon: 'server',
        link: '/file-explorer',
    },
    {
        id: 1,
        label: 'MENUITEMS.ADMIN.TEXT',
        icon: 'settings',
        subItems: [
            {
                id: 2,
                label: 'MENUITEMS.USER.TEXT',
                icon: 'users',
                link: '/user-list',
            }, 
            {
                id: 3,
                label: 'MENUITEMS.CUSTOMER.TEXT',
                icon: 'users',
                link: '/customer-list',
            },
            {
                id: 4,
                label: 'MENUITEMS.PLANT.TEXT',
                icon: 'box',
                link: '/plant-list',
            },
            {
                id: 5,
                label: 'MENUITEMS.SITE.TEXT',
                icon: 'sliders',
                link: '/site-list',
            },
        ]
    },
   
    // {  // logout and Profile option designed in html directly
    //     id: 8,
    //     label: 'MENUITEMS.AUTHENTICATION.LIST.LOGOUT',
    //     icon: 'user',
    //     link: '',
    // },
];

