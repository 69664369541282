export const environment = {
  production: true,
  defaultauth: 'fake-backend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  apiURL: 'https://aeye.aquatech.com/aquawave',
  fastApiURL: 'https://aeye.aquatech.com/aquafusion',
  aquapulseURL: "https://aeye.aquatech.com/aquapulse"
};